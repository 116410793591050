import {useEffect, useState} from "react";
import {MediaImage} from "../media/media--image";
import {HERO_IMAGE_STYLE} from "../../../types/image-styles";
import {FrostedGlassContainer} from "../../atoms/gradient-container";
import {Button} from "../../atoms/button";
import classNames from "classnames";
import dynamic from "next/dynamic";
import {DynamicVideoPlayer} from "../../organisms/dynamic-video-player";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphVideoBlock({paragraph, ...props}) {
  const [play, setPlay] = useState(false);
  const [played, setPlayed] = useState(false);
  const [videoReady, setVideoReady] = useState<boolean>(false);
  const [scale, setScale] = useState<boolean>();
  const [loadVimeo, setLoadVimeo] = useState<boolean>();
  const videoUrl = paragraph?.field_video_block_video?.field_media_oembed_video ?? "";

  function videoElement() {
    return (
      <div className={classNames("absolute w-full h-full transition-opacity duration-1000", {"opacity-1": (play || played), "opacity-0": (!play && !played)})}>
        {loadVimeo && (
          <DynamicVideoPlayer className="react-player--video-block object-fill" width={"100%"} height={"100%"} frameBorder={0} url={videoUrl} volume={1} playing={play} onPause={() => setPlay(false)} controls style={{
            minHeight: "100%", minWidth: "100%", position: "absolute", left: 0, top: 0, objectFit: "fill"}
          }/>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (typeof window === "undefined" || videoReady) return;
    setVideoReady(true);
  }, [videoUrl]);

  return (
    <>
      {videoUrl && (
        <div className="relative">
          <div className={classNames(
            "relative w-full mx-auto min-h-[600px] flex md:flex-col h-full items-end overflow-hidden rounded-md video-block-container",
            {"box-shadow-md": (!play && !played)},
            {"max-w-4xl !min-h-[450px]": !paragraph?.field_video_block_full}
          )}>
            <MediaImage
              imageStyle={HERO_IMAGE_STYLE}
              media={paragraph.field_video_block_image}
              key={`hero-image--${paragraph.field_video_block_image.id}`}
              sizes={`(min-width: 1024px) 860px, 600px`}
              onClick={() => {setPlay(!play); setPlayed(true); setLoadVimeo(true);}}
              className={classNames(
              "absolute inset-0 scale-100 cursor-pointer hover:scale-105 transition-all overflow-hidden rounded-md opacity-1 duration-1000", {"opacity-0": (play || played), "z-10": (!play && !played), "scale-105": scale}
              )} style={{objectFit: "cover", height: "100%", width: "100%", margin: "auto"}}/>

            {videoReady && videoElement()}

            {paragraph?.field_video_block_panel && (
              <FrostedGlassContainer className={classNames(
                "w-2/5 h-full !border-0 flex-grow md:right-1 flex items-center px-6 py-6 text-center md:text-left md:px-14 !from-transparent flex-col justify-center md:rounded-md md:rounded-tl-none md:rounded-bl-none transition-all duration-1000 relative",
                {"md:left-[100%] max-md:bottom-[-1000px]": (play || played), "max-md:bottom-0 z-10 md:left-[2px]": (!play && !played)}
              )}>
                <div className="text-3xl md:text-4xl text-white drop-shadow-md font-bold mb-4 md:mb-10">
                  <FormattedText processed={paragraph?.field_video_block_headline?.processed ?? ""} />
                </div>
                <Button icon={"play"} invert={true} color={"white"} hover={{color: "primary"}}
                        height={25} width={25}
                        className="text-white border-white"
                        callback={() => {setPlay(true); setPlayed(true); setLoadVimeo(true);}}>{paragraph?.field_video_block_button_text}</Button>
              </FrostedGlassContainer>
            )}
          </div>
          {paragraph?.field_video_block_caption?.processed && (
            <div className={classNames(
              "relative w-full mx-auto flex md:flex-col h-full pt-4",
              {"max-w-4xl": !paragraph?.field_video_block_full}
            )}>
              <FormattedText processed={paragraph.field_video_block_caption?.processed}/>
            </div>
          )}
        </div>
      )}
    </>
  )
}
